@import url(https://fonts.googleapis.com/css2?family=Mouse+Memoirs&display=swap);
/*@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;700;800&display=swap");*/
/*@import url('https://fonts.googleapis.com/css2?family=Fresca&display=swap');*/

:root {
	--minter-base-color: #FFF;
	--minter-inverse-color: #000;
	--minter-success: #24a13f;
	--minter-error: #ca2f24;
	--minter-warning: #ca2f24;
	--minter-notice-background: rgba(0,0,0,0.05);
}

:root [data-style="light"] {
	--minter-base-color: #000;
	--minter-inverse-color: #FFF;
	--minter-notice-background: rgba(255,255,255,0.05);
}

:root [data-style="transparent"] {
	--minter-base-color: #151515; /*transparent;*/
	--minter-inverse-color: #FFF;
	--minter-notice-background: rgba(255,255,255,0.05);
}

.minter_container {
	font-family: Arial, Helvetica, sans-serif;
	background-color: #FFF;
	background-color: var(--minter-base-color);
	display: flex;
	flex-direction: column;
	border-radius: 20px;
	border: 10px solid #000;
	width: 100%;
	transition: 0.3s;
	margin: 30px 0 10px 0;
	position: relative;
}
.minter_body {
	padding: 15px;
	margin-top: 14px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}
.minter_body p {
	text-align: center;
	color: #000;
	color: var(--minter-inverse-color);
}

.minter_header_text {
	font-size: 44px;
	letter-spacing: -2px;
	font-weight: bold;
	line-height: 50px;
	text-align: center;
	margin:  0;
	margin-top: 40px;
	color: #000;
	color: var(--minter-inverse-color);
}
.minter_header_subtext {
	font-size: 24px;
	letter-spacing: -1px;
	font-weight: normal;
	text-align: center;
	margin:  0;
	padding: 3px;
	color: #000;
	color: var(--minter-inverse-color);
}

.minter_block {
    display: flex;
    justifyContent: space-between;
    width: 100%;
    margin: 0;
    padding: 0;
    margin-left: 6px;
}
.minter_block p {
	color: #000;
	color: var(--minter-inverse-color);
	textAlign: center;
}
.minter_spacer {
	width: 20px;
}

.minter_block_info {
	text-align: center;
	padding: 8px 0;
	margin:  20px 0;
	font-weight: normal;
	color: #000;
	color: var(--minter-inverse-color);
	font-size: 20px;
	background-color: rgba(0,0,0,0.04);
	border-radius: 10px;
}
span.minter_info_spacer {
	padding: 0 6px;
}
span.minter_info_bold {
	font-weight: bold;
	padding-right: 6px;
}

.minter_footer {
	border-bottom-left-radius: 15px;
	border-bottom-right-radius: 15px;
	padding: 20px 40px;
	align-items: center;
	margin-bottom: 26px;
}
.minter_notice_text {
	color: #000 !important;
	color: var(--minter-inverse-color) !important;
	background-color: rgba(0,0,0,0.05);
	background-color: var(--minter-notice-background);
	font-weight: normal;
	text-align: center;
	margin: 30px 0 0 0;
	padding: 20px 20px;
	border-radius: 10px;
	width: 80%;
}
.minter_button {
    cursor: pointer;
    text-align: center;
    text-decoration: none;
    outline: none;
    color: white;
    background-color: #000;
    border-radius: 50px;
    line-height: 40px;
    padding-top: 6px;
    font-size: 24px;
    font-weight: bold;
    border: 8px solid #000;
    border: 8px solid var(--minter-inverse-color);
    height: 46px;
    /* width: 76%; */
    margin: auto;
    margin-bottom: 2px;
}
.minter_button:active {
	background-color: #000;
	background-color: var(--minter-inverse-color);
}
.footer-spacer {
	height:40px;
	width:100%;
}
.notice_notice_text {
	line-height: 28px;
	font-size: 18px;
}
.notice_link {
	color: white;
}
.footer-text {
	width:100%;
	color: white;
	font-size: 18px;
}
.footer-address {
	display: block;
	float: left;
}
a.footer_link {
	color: white;
	display: block;
	float: right;
}
.mint_notice {
	color: #000 !important;
	color: var(--minter-inverse-color) !important;
	font-weight: normal;
	text-align: center;
	margin: auto;
	padding: 20px 20px 0 20px;
	border-radius: 10px;
	width: 80%;
	font-size: 14px;
}
.minter_amount_button {
	display: inline-block;
	font-size: 40px;
	line-height: 30px;
	cursor: pointer;
	text-align: center;
	text-decoration: none;
	outline: none;
	color: #000;
	color: var(--minter-inverse-color);
	background-color: #FFF;
	background-color: var(--minter-base-color);
	margin-bottom: 2px;
	border-radius: 50px !important;
	padding:  8px 24px;
	font-weight: bold;
	border: 7px solid #000;
	border: 7px solid var(--minter-inverse-color);
}
.minter_amount_button.plus {
	padding-left: 40px;
	padding-right: 40px;
}
.minter_amount_button.minus {
	padding-top: 2px;
	font-size: 50px;
	padding-left: 44px;
	padding-right: 44px;
}
@media only screen and (max-width: 600px) {
	.minter_amount_button.plus {
		padding-left: 24px;
		padding-right: 24px;
	}
	.minter_amount_button.minus {
		padding-top: 2px;
		font-size: 50px;
		padding-left: 28px;
		padding-right: 28px;
	}
}
.minter_amount_button:disabled {
	opacity: 0.5;
}
.minter_amount_button:active {
	background-color: #000;
	background-color: var(--minter-inverse-color);
}

.minter_mint_box {
	padding: 4px 14px;
}
.minter_mint_text {
	font-size: 18px;
	line-height: 18px;
	color: #000;
	color: var(--minter-inverse-color);
	text-align: center;
}
.minter_mint_amount {
	font-size: 30px;
	color: #000;
	color: var(--minter-inverse-color);
	text-align: center;
	font-weight: bold;
}

.minter_eth_logo {
	height:  22px;
	margin-top: -4px;
	padding-right: 6px;
}

.minter_connect_button {
	color: #FFF;
	color: var(--minter-base-color);
	background-color: #000;
	background-color: var(--minter-inverse-color);
}
.minter_connect_button {
	border-radius: 8px;
	border: none;
	padding: 6px 22px;
	font-weight: bold;
}

.minter_connect_button:disabled,
.minter_amount_button:disabled,
.minter_button:disabled {
	opacity: 0.5;
}

._90 {
	-webkit-transform: rotate(90deg);
	transform: rotate(-90deg);
}


